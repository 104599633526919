/** @format */

import DSM from '../../lib/DSM';
import config from '../../config';
import { toCamelCase } from '../../lib/camelSnake';
import { fromJS } from 'immutable';

let initialState = fromJS({
  //map of feeds
  feeds: {},
});

const functions = {
  //you need this action itterator wherever you use the pricefeed websocket
  data: (state, action) => {
    state = state.asMutable();

    //apply multiple things
    if (action.data.data && typeof action.data.data === 'object') {
      for (let act of action.data.data) {
        state = reducer(state, { type: act[0], data: typeof act[1] !== 'object' ? act : act[1] });
      }
    }

    return state.asImmutable();
  },

  ////// PRICEFEED STREAM

  //update feed when a new event update is received
  //[PRICEFEED]
  event: (state, action) => {
    let eventId = action.data[0][1];
    DSM.forceIntervalRequest(`feed/${eventId}`, true);
    return state;
  },

  ////// FEEDS

  //open an event feed
  eventOpenFeed: (state, action) => {
    let eventId = action.data.eventId;
    let sport = action.data.sport;

    let openDraggable = true;
    // might not be passed in which case assume true
    if (action.data.openDraggable === false) {
      openDraggable = false;
    }

    //already open
    //perhaps repositions?
    if (state.getIn(['feeds', eventId], null)) {
      return state;
    }

    //basically we're highjacking price history because it contains the scoredata.
    //the bet type doesn't matter, it'll return no prices, but the scores will be there.
    DSM.ensureOne(
      `/web/offerhist/${sport}/${eventId}/null/`,
      {
        method: 'GET',
        interval: config.timings.feedRefresh,
        message: 'eventOpenFeedResponse',
        extras: {
          eventId: action.data.eventId,
        },
      },
      action.data.actions,
      `feed/${eventId}`
    );

    return state.setIn(
      ['feeds', eventId],
      fromJS({
        ...action.data,
        isLoading: true,
        openDraggable,
        zIndex: +new Date(),
        ballBos: {
          x: 50,
          y: 50,
        },
      })
    );
  },

  //feed data result
  //this is done for football atm, might have to do something else for other sports
  eventOpenFeedResponse: (state, action) => {
    if (action.data.status === 'ok') {
      let events = [];
      //should insert orders?
      //TODO

      //handle main scores
      let scores = action.data.data.scores;
      if (scores) {
        let scoreHome = 0;
        let scoreAway = 0;
        for (let scr of scores) {
          let team = '';
          let time = new Date(scr[0] * 1000);

          //we need to detect what team it was `for`
          //so we compare scores to previous scores
          if (scr[1][0] > scoreHome) {
            team = 'home';
            scoreHome = scr[1][0];
          } else if (scr[1][1] > scoreAway) {
            team = 'away';
            scoreAway = scr[1][1];
          }

          if (team) {
            events.push({
              team: team,
              time: time,
              score: scr[1],
              type: 'goal',
            });
          }
        }
      }

      //handle red cards
      let rcs = action.data.data.rc;
      if (rcs) {
        let rcHome = 0;
        let rcAway = 0;
        for (let rc of rcs) {
          let team = '';
          let time = new Date(rc[0] * 1000);

          //we need to detect what team it was `for`
          //so we compare scores to previous scores
          if (rc[1][0] > rcHome) {
            team = 'home';
            rcHome = rc[1][0];
          } else if (rc[1][1] > rcAway) {
            team = 'away';
            rcAway = rc[1][1];
          }

          if (team) {
            events.push({
              team: team,
              time: time,
              score: rc[1],
              type: 'redcard',
            });
          }
        }
      }

      //handle corners
      let corns = action.data.data.corn;
      if (corns) {
        let cornHome = 0;
        let cornAway = 0;
        for (let corn of corns) {
          let team = '';
          let time = new Date(corn[0] * 1000);

          //we need to detect what team it was `for`
          //so we compare scores to previous scores
          if (corn[1][0] > cornHome) {
            team = 'home';
            cornHome = corn[1][0];
          } else if (corn[1][1] > cornAway) {
            team = 'away';
            cornAway = corn[1][1];
          }

          if (team) {
            events.push({
              team: team,
              time: time,
              score: corn[1],
              type: 'corner',
            });
          }
        }
      }

      //should insert start
      //should insert half-time
      //ui should sort them
      //events = _.sortBy(events, 'time')
      return state.mergeDeepIn(['feeds', action.data.extras.eventId], {
        events,
        isLoading: false,
      });
    } else {
      //handled in base
      return state;
    }
  },

  //push draggable to the front
  eventPopFeed: (state, action) => {
    let eventId = action.data.eventId;
    return state.setIn(['feeds', eventId, 'zIndex'], +new Date());
  },

  //close feed and clean up streams
  eventCloseFeed: (state, action) => {
    let eventId = action.data.eventId;
    //stop the stream
    DSM.stop(`feed/${eventId}`, true);
    return state.removeIn(['feeds', eventId]);
  },

  //close all feeds and clean up streams
  closeAllFeeds: (state, _action) => {
    DSM.stopAllStartingWith('feed/');
    return state.remove('feeds');
  },

  ////// LOGOUT

  //reset some state
  logout: (state, _action) => {
    DSM.stopAllStartingWith('feed/');
    return state.remove('feeds');
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
